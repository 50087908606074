// General Colors
$gray-100: #e3e3e3;
$gray-200: #cac9c9;
$gray-300: #b1afaf;
$gray-400: #989595;
$gray-500: #7e7c7c;
$gray-600: #646262;
$gray-700: #4b4949;
$gray-800: #323131;
$gray-900: #171717;

$black: #000000;
$black-rgb: rgb(0, 0, 0);
$white: #ffffff;
$white-rgb: rgb(255, 255, 255);
$muted: #646262;
$muted-rgb: rgb(100, 98, 98);

// Brand Color Variables
$carbon: #323131;
$carbon-rgb: rgb(50, 49, 49);
$alabaster: #eeebe1;
$alabaster-rgb: rgb(238, 235, 225);

$blue: #4188dc;
$blue-rgb: rgb(65, 136, 220);
$sky-blue: #8bc4d1;
$sky-blue-rgb: rgb(139, 196, 209);
$deep-blue: #204648;
$deep-blue-rgb: rgb(32, 70, 72);

$orange: #ff7500;
$orange-rgb: rgb(255, 117, 0);
$deep-orange: #5e2500;
$deep-orange-rgb: rgb(94, 37, 0);

$green: #4b9959;
$green-rgb: rgb(75, 153, 89);
$deep-green: #0c3d0c;
$deep-green-rgb: rgb(12, 61, 12);
$light-green: #79bb85;
$light-green-rgb: rgb(121, 187, 133);

// Semantic Colors
$red: #d72323;
$red-rgb: rgb(215, 35, 35);
$yellow: #fbbf24;
$yellow-rgb: rgb(251, 191, 36);

// Additional Colors
$facebook: #4267b2;
$facebook-hover: #34518d;
$facebook-disabled: $facebook;
$facebook-focus: #34518d;
$facebook-focus-shadow: rgba(94, 126, 190, 0.5);

$info: $sky-blue;
$info-hover: #70b6c6;
$info-disabled: $sky-blue;
$info-focus: #70b6c6;
$info-focus-shadow: 0 0 0 0.2rem rgba(122, 170, 181, 0.5);
$info-dropdown: #66b1c3;
$info-dropdown-focus-shadow: 0 0 0 0.2rem rgba(122, 170, 181, 0.5);

$success: $green;
$success-hover: #3e7f4a;
$success-disabled: $green;
$success-focus: #3e7f4a;
$success-focus-shadow: 0 0 0 0.2rem rgba(102, 168, 114, 0.5);
$success-dropdown: #3a7745;
$success-dropdown-focus-shadow: 0 0 0 0.2rem rgba(102, 168, 114, 0.5);

$warning: $yellow;
$warning-hover: #f4b104;
$warning-disabled: $yellow;
$warning-focus: #f4b104;
$warning-focus-shadow: 0 0 0 0.2rem rgba(217, 166, 34, 0.5);
$warning-dropdown: #e8a804;
$warning-dropdown-focus-shadow: 0 0 0 0.2rem rgba(217, 166, 34, 0.5);

$danger: $red;
$danger-hover: #b61e1e;
$danger-disabled: $red;
$danger-focus: #b61e1e;
$danger-focus-shadow: 0 0 0 0.2rem rgba(221, 68, 68, 0.5);
$danger-dropdown: #ab1c1c;
$danger-dropdown-focus-shadow: 0 0 0 0.2rem rgba(221, 68, 68, 0.5);

// Color Usage
$primary: $orange;
$primary-hover: #d96300;
$primary-disabled: $orange;
$primary-focus: #d96300;
$primary-focus-shadow: 0 0 0 0.2rem rgba(255, 138, 38, 0.5);
$primary-dropdown: #cc5e00;
$primary-dropdown-focus-shadow: 0 0 0 0.2rem rgba(255, 138, 38, 0.5);

$secondary: $carbon;
$secondary-hover: #1f1e1e;
$secondary-disabled: $carbon;
$secondary-focus: #181818;
$secondary-focus-shadow: 0 0 0 0.2rem rgba(81, 80, 80, 0.5);
$secondary-dropdown: #181818;
$secondary-dropdown-focus-shadow: 0 0 0 0.2rem rgba(81, 80, 80, 0.5);

$light: $alabaster;
$light-rgba: rgba(238, 235, 225, 0.5);
$light-hover: #d0d0d0;
$light-disabled: $alabaster;
$light-focus: #d0d0d0;
$light-focus-shadow: 0 0 0 0.2rem rgba(196, 196, 196, 0.5);
$light-dropdown: #cacaca;
$light-dropdown-focus-shadow: 0 0 0 0.2rem rgba(196, 196, 196, 0.5);
$light-shade: #d1cfc6;
$light-tint: #f0ede4;

$dark: $carbon;
$dark-hover: #1f1e1e;
$dark-disabled: $carbon;
$dark-focus: #1f1e1e;
$dark-focus-shadow: 0 0 0 0.2rem rgba(81, 80, 80, 0.5);
$dark-dropdown: #181818;
$dark-dropdown-focus-shadow: 0 0 0 0.2rem rgba(81, 80, 80, 0.5);

// Text Pairs
$info-text: $gray-900;
$success-text: $gray-900;
$warning-text: $gray-900;
$danger-text: $white;

$primary-text: $gray-900;
$secondary-text: $alabaster;
$light-text: $carbon;
$dark-text: $alabaster;
$info-text: $gray-900;
$success-text: $gray-900;
$warning-text: $gray-900;
$danger-text: $alabaster;
$white-text: $carbon;

$facebook-text: $white;
$blue-text: $gray-900;
$sky-blue-text: $gray-900;
$deep-blue-text: $alabaster;
$orange-text: $gray-900;
$deep-orange-text: $alabaster;
$green-text: $gray-900;
$deep-green-text: $alabaster;

// z-index
$zindex-jumbotron-content: 990;
$zindex-dropdown: 1000;
$zindex-sticky: 1020;
$zindex-sticky-nav: 1021;
$zindex-fixed: 1030;
$zindex-modal-backdrop: 1040;
$zindex-modal: 1050;
$zindex-popover: 1060;
$zindex-tooltip: 1070;
$zindex-top: 2000;

// border radii
$radius-button: 3rem;
$radius-card: 0.5rem;
$form-radius: 0.5rem;

// Display
$flex-grow: (
  '0': 0,
  '1': 1,
  '2': 2,
  '3': 3,
  '4': 4,
  '5': 5,
  '6': 6,
);

$flex-shrink: (
  '0': 0,
  '1': 1,
);

$flex-basis: (
  '0': 0,
  '1': 1,
  '100': 100%,
);

$flex-gap: (
  '1': 0.25rem,
  '2': 0.5rem,
  '3': 1rem,
  '4': 1.5rem,
  '5': 2rem,
  '6': 3rem,
);

$breakpoints: (
  'small': 576px,
  'medium': 768px,
  'large': 992px,
  'xlarge': 1200px,
);
