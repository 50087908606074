@import 'src/assets/styles/utils/_variables.scss';


.cursor {
  cursor: auto;

  &-pointer {
    @extend .cursor;
    cursor: pointer;
  }
}

ul,
.pagination {
  margin-bottom: 0 !important;
}

html {
  scroll-behavior: smooth;
}

body {
  min-height: 100vh;
}

img {
  -moz-user-select: none;
  -webkit-user-select: none;
  user-select: none;
}

/* width */
::-webkit-scrollbar {
  width: 8px;
  height: 4px;
}

/* Track */
::-webkit-scrollbar-track {
  background: $gray-200;
  border-radius: $radius-button;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: $dark;
  border-radius: $radius-button;
  cursor: grab !important;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: $dark-hover;
}

::selection {
  color: $dark;
  background: $primary;
}

.mapboxgl-ctrl-geocoder--input {
  width: 100% !important;
  max-width: 100% !important;
  box-shadow: none !important;
  height: 100% !important;
  padding: 0 !important;

  @media (min-width: 640px) {
    width: 100% !important;
    max-width: 100% !important;
    box-shadow: none !important;
    height: 100% !important;
    padding: 0 !important;
  }

  &:focus {
    outline: none;

    @media (min-width: 640px) {
      outline: none;
    }
  }
}

.mapboxgl-ctrl-geocoder {
  width: 100% !important;
  max-width: 100% !important;
  box-shadow: none !important;
  height: 100% !important;
  border-radius: 0.5rem;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-around;
  padding: 0 0.5rem;
  gap: 0.5rem;
  font-family: 'neue haas unica', sans-serif;

  @media (min-width: 640px) {
    font-family: 'neue haas unica', sans-serif;
    width: 100% !important;
    max-width: 100% !important;
    box-shadow: none !important;
    height: 100% !important;
    border-radius: 0.5rem;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-around;
    padding: 0 0.5rem;
    gap: 0.5rem;
  }

  .mapboxgl-ctrl-geocoder--icon,
  mapboxgl-ctrl-geocoder--button,
  .mapboxgl-ctrl-geocoder--pin-right > * {
    position: unset !important;
    @media (min-width: 640px) {
      position: unset !important;
    }
  }
  .suggestions-wrapper {
    background-color: $alabaster !important;
    @media (min-width: 640px) {
      background-color: $alabaster !important;
    }
    .suggestions {
      @media (min-width: 640px) {
        background-color: $alabaster !important;
      }
      background-color: $alabaster !important;
    }
  }
}

.thin-container {
  max-width: 736px;
  width: 100%;

  &-column {
    @extend .thin-container;
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }
}

.accordion-toggle > button {
  width: 100%;
}



.z-dropdown {
  z-index: $zindex-dropdown;
}

.z-sticky {
  z-index: $zindex-sticky;
}

.z-sticky-nav {
  z-index: $zindex-sticky-nav;
}

.z-fixed {
  z-index: $zindex-fixed;
}

.z-modal-backdrop {
  z-index: $zindex-modal-backdrop;
}

.z-modal {
  z-index: $zindex-modal;
}

.z-popover {
  z-index: $zindex-popover;
}

.z-tooltip {
  z-index: $zindex-tooltip;
}

.z-jumbotron-content {
  z-index: $zindex-jumbotron-content;
}

.z-top {
  z-index: $zindex-top;
}

.jumbotron-min-height {
  min-height: 60vh;
}

@media (max-width: 575.98px) {
  .jumbotron-min-height {
    min-height: auto;
  }
}

.navbar-anchor {
  position: relative;
  top: -80px;
  left: 0
}

.w-fit-content {
  width: fit-content;
}

.scroll-to-top-button {
  position: fixed;
  bottom: 1rem;
  right: 1rem;
  padding: 0.75rem;
  background-color: $primary;
  color: $primary-text;
  border: none;
  border-radius: $radius-button;
  font-size: 14px;
  cursor: pointer;
  opacity: 0.8;
  transition: opacity 0.3s ease;
  z-index: $zindex-fixed;
  display: flex;
}

.scroll-to-top-button:hover {
  opacity: 1;
}

carousel.announcement-carousel {
  .carousel.slide {
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    a {
      &.carousel-control {
        background: $carbon;
      }

      &.right {
        position: relative;
        //display: none;
      }
      &.left {
        position: relative;
      }

      &.carousel-control-prev {
        width: fit-content;
        order: 1;
        display: none;
      }

      &.carousel-control-next {
        width: fit-content;
        order: 3;
        display: none;
      }
    }
    .carousel-inner {
      order: 2;
    }
  }

}

.btn-link {
  cursor: pointer;
}

.form-check {
  label {
    cursor: pointer;
    input {
      cursor: pointer;
    }
  }
}

#cc-main {
  color: #171717 !important;
  --cc-bg: #eeebe1;
  --cc-primary-color: #ff7500;
  --cc-secondary-color: #323131;

  --cc-btn-primary-bg: #ff7500;
  --cc-btn-primary-color: #171717;
  --cc-btn-primary-border-color: var(--cc-btn-primary-bg);
  --cc-btn-primary-hover-bg: #d96300;
  --cc-btn-primary-hover-color: #171717;
  --cc-btn-primary-hover-border-color: var(--cc-btn-primary-hover-bg);

  --cc-btn-secondary-bg: #323131;
  --cc-btn-secondary-color: #eeebe1;
  --cc-btn-secondary-border-color: var(--cc-btn-secondary-bg);
  --cc-btn-secondary-hover-bg: #1f1e1e;
  --cc-btn-secondary-hover-color: #eeebe1;
  --cc-btn-secondary-hover-border-color: var(--cc-btn-secondary-hover-bg);

  --cc-separator-border-color: var(--cc-bg);

  --cc-toggle-on-bg: var(--cc-btn-primary-bg);
  --cc-toggle-off-bg: #667481;
  --cc-toggle-on-knob-bg: #ffffff;
  --cc-toggle-off-knob-bg: var(--cc-toggle-on-knob-bg);

  --cc-toggle-enabled-icon-color: var(--cc-bg);   // yes (v tick)
  --cc-toggle-disabled-icon-color: var(--cc-bg);  // no (x tick)

  --cc-toggle-readonly-bg: #171717 ;
  --cc-toggle-readonly-knob-bg: #fff;
  --cc-toggle-readonly-knob-icon-color: var(--cc-toggle-readonly-bg);

  --cc-section-category-border: var(--cc-cookie-category-block-bg);

  --cc-cookie-category-block-bg: var(--cc-bg);
  --cc-cookie-category-block-border: var(--cc-bg);
  --cc-cookie-category-block-hover-bg: var(--cc-bg);
  --cc-cookie-category-block-hover-border: var(--cc-bg);
  --cc-cookie-category-expanded-block-bg: var(--cc-bg);
  --cc-cookie-category-expanded-block-hover-bg: var(--cc-bg);

  --cc-overlay-bg: rgba(0, 0, 0, 0.65);
  --cc-webkit-scrollbar-bg: var(--cc-section-category-border);
  --cc-webkit-scrollbar-hover-bg: var(--cc-btn-primary-hover-bg);

  --cc-footer-bg: var(--cc-bg);
  --cc-footer-color: var(--cc-secondary-color);
  --cc-footer-border-color: #b1afaf;

  --cc-font-family: 'neue haas unica',sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
  --cc-modal-border-radius: 0.5rem;
  --cc-btn-border-radius: 3rem;
  --cc-modal-transition-duration: .25s;
  --cc-modal-margin: 1rem;
  --cc-z-index: 1050;
}
