.animate__animated {
  visibility: visible !important;
}
.hide-on-init {
  visibility: hidden;
}

.os-heading-copy {
  font-size: 5.75rem;
  font-family: 'mpi gothic', sans-serif !important;
}

.os-body-copy {
  font-size: 1.25rem;
}

.text-card-orange {
  background-color: $orange;
  color: $deep-orange;

  &::selection {
    background: $deep-orange; /* WebKit/Blink Browsers */
    color: $orange;
  }

  b {
    &::selection {
      background: $deep-blue; /* WebKit/Blink Browsers */
      color: $sky-blue;
    }
  }
}

.text-card-sky-blue {
  background-color: $sky-blue;
  color: $deep-blue;

  &::selection {
    background: $deep-blue; /* WebKit/Blink Browsers */
    color: $sky-blue;
  }

  b {
    &::selection {
      background: $deep-orange; /* WebKit/Blink Browsers */
      color: $orange;
    }
  }
}

.text-card-carbon {
  background-color: $carbon;
  color: $alabaster;

  &::selection {
    background: $alabaster; /* WebKit/Blink Browsers */
    color: $carbon;
  }

  b {
    &::selection {
      background: $deep-orange; /* WebKit/Blink Browsers */
      color: $orange;
    }
  }
}

// `xl` applies to large devices (desktops, less than 1200px)
@media (max-width: 1199.98px) {

  .os-heading-copy {
    font-size: 4.25rem;
  }

  .os-body-copy {
    font-size: 1.25rem;
  }

}

// `lg` applies to medium devices (tablets, less than 992px)
@media (max-width: 991.98px) {

  .os-heading-copy {
    font-size: 4.0rem;
  }

  .os-body-copy {
    font-size: 1.125rem;
  }

}

// `md` applies to small devices (landscape phones, less than 768px)
@media (max-width: 767.98px) {

  .os-heading-copy {
    font-size: 3.75rem;
  }

  .os-body-copy {
    font-size: 1rem;
  }
}

// `sm` applies to x-small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {
  .os-heading-copy {
    font-size: 3.5rem;
  }

  .os-body-copy {
    font-size: 1rem;
  }
}
