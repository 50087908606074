// Mapbox
@import '~mapbox-gl/dist/mapbox-gl.css';
@import '~@mapbox/mapbox-gl-geocoder/lib/mapbox-gl-geocoder.css';

// Base
@import 'src/assets/styles/base/fonts';
@import 'src/assets/styles/base/forms';
@import 'src/assets/styles/base/images';

// Utilities
@import 'src/assets/styles/utils/_variables.scss';
@import 'src/assets/styles/utils/general';
@import 'src/assets/styles/utils/display';

// Components
@import 'src/assets/styles/components/_modals.scss';
@import 'src/assets/styles/components/_ngx-timeline.scss';
@import 'src/assets/styles/components/_popovers.scss';
@import 'src/assets/styles/components/_our-story.scss';
@import 'src/assets/styles/components/_restaurant-details-accordion.scss';

/* Importing Datepicker SCSS file. */
@import 'node_modules/ngx-bootstrap/datepicker/bs-datepicker';

/* Importing Bootstrap SCSS file. */
@import 'node_modules/bootstrap/scss/bootstrap.scss';

// Bootstrap (Keep on bottom)
@import 'src/assets/styles/utils/_bootstrap.scss';
