// Headers
@font-face {
  font-family: 'filmotype ford';
  src: url('../../fonts/Filmotype - Filmotype Ford.otf');
  font-display: swap;
}

// Header Alternative
@font-face {
  font-family: 'mpi republic gothic';
  src: url('../../fonts/mpressInteractive - MPIRepublicGothic.otf');
  font-display: swap;
}

// Subheaders
@font-face {
  font-family: 'mpi gothic';
  src: url('../../fonts/mpressInteractive - MPIGothic.otf');
  font-display: swap;
}

// Body
@font-face {
  font-family: 'neue haas unica';
  src: url('../../fonts/Linotype - Neue Haas Unica Pro UltraLight.otf');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'neue haas unica';
  src: url('../../fonts/Linotype - Neue Haas Unica Pro UltLight It.otf');
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'neue haas unica';
  src: url('../../fonts/Linotype - Neue Haas Unica Pro Thin.otf');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'neue haas unica';
  src: url('../../fonts/Linotype - Neue Haas Unica Pro Thin Italic.otf');
  font-weight: 200;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'neue haas unica';
  src: url('../../fonts/Linotype - Neue Haas Unica Pro Light.otf');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'neue haas unica';
  src: url('../../fonts/Linotype - Neue Haas Unica Pro Light It.otf');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'neue haas unica';
  src: url('../../fonts/Linotype - Neue Haas Unica Pro.otf');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'neue haas unica';
  src: url('../../fonts/Linotype - Neue Haas Unica Pro Italic.otf');
  font-weight: 400;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'neue haas unica';
  src: url('../../fonts/Linotype - Neue Haas Unica Pro Medium.otf');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'neue haas unica';
  src: url('../../fonts/Linotype - Neue Haas Unica Pro Medium It.otf');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'neue haas unica';
  src: url('../../fonts/Linotype - Neue Haas Unica Pro Bold.otf');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'neue haas unica';
  src: url('../../fonts/Linotype - Neue Haas Unica Pro Bold Italic.otf');
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'neue haas unica';
  src: url('../../fonts/Linotype - Neue Haas Unica Pro Heavy.otf');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'neue haas unica';
  src: url('../../fonts/Linotype - Neue Haas Unica Pro Heavy It.otf');
  font-weight: 700;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'neue haas unica';
  src: url('../../fonts/Linotype - Neue Haas Unica Pro Black.otf');
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'neue haas unica';
  src: url('../../fonts/Linotype - Neue Haas Unica Pro Black It.otf');
  font-weight: 800;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'neue haas unica';
  src: url('../../fonts/Linotype - Neue Haas Unica Pro XBlack.otf');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'neue haas unica';
  src: url('../../fonts/Linotype - Neue Haas Unica Pro XBlack It.otf');
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

// Monospace

@font-face {
  font-family: 'courier-prime';
  src: url('../../fonts/Courier Prime.ttf');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'courier-prime';
  src: url('../../fonts/Courier Prime Italic.ttf');
  font-weight: 400;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'courier-prime';
  src: url('../../fonts/Courier Prime Bold.ttf');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'courier-prime';
  src: url('../../fonts/Courier Prime Bold Italic.ttf');
  font-weight: 700;
  font-style: italic;
  font-display: swap;
}

h1 {
  font-family: 'mpi republic gothic', sans-serif !important;
  text-transform: uppercase;
  margin-bottom: 0 !important;
  letter-spacing: 1px;
}

h2 {
  font-family: 'mpi republic gothic', sans-serif !important;
  text-transform: uppercase;
  margin-bottom: 0 !important;
  letter-spacing: 1px;
}

h3 {
  font-family: 'mpi republic gothic', sans-serif !important;
  text-transform: uppercase;
  margin-bottom: 0 !important;
  letter-spacing: 1px;
}

h4 {
  font-family: 'mpi gothic', sans-serif !important;
  text-transform: uppercase;
  margin-bottom: 0 !important;
  letter-spacing: 1px;
}

h5 {
  font-family: 'mpi gothic', sans-serif !important;
  text-transform: uppercase;
  margin-bottom: 0 !important;
  letter-spacing: 1px;
}

h6 {
  font-family: 'mpi gothic', sans-serif !important;
  text-transform: uppercase;
  margin-bottom: 0 !important;
  letter-spacing: 1px;
}

.lead {
  font-family: 'neue haas unica', sans-serif !important;
  margin-bottom: 0 !important;
}

.blockquote {
  font-family: 'neue haas unica', sans-serif !important;
  margin-bottom: 0 !important;
}

.blockquote-footer {
  font-family: 'neue haas unica', sans-serif !important;
  margin-bottom: 0 !important;
  margin-top: 0 !important;
}

body {
  font-family: 'neue haas unica', sans-serif !important;
  font-size: 16px;
  margin-bottom: 0 !important;
}

.btn {
  font-family: 'neue haas unica', sans-serif;
  letter-spacing: 0.5px;
}

.display-1 {
  word-break: break-word;
  font-family: 'filmotype ford', sans-serif !important;
}

.display-2 {
  word-break: break-word;
  font-family: 'filmotype ford', sans-serif !important;
}

.display-3 {
  word-break: break-word;
  font-family: 'filmotype ford', sans-serif !important;
}

.display-4 {
  word-break: break-word;
  font-family: 'filmotype ford', sans-serif !important;
}

.display-5 {
  word-break: break-word;
  font-family: 'filmotype ford', sans-serif !important;
}

.font-filmotype {
  font-family: 'filmotype ford', sans-serif !important;
}

.font-neue {
  font-family: 'neue haas unica', sans-serif;
}

.font-mpi-gothic {
  font-family: 'mpi gothic', sans-serif !important;
}

.font-mpi-republic {
  font-family: 'mpi republic gothic', sans-serif !important;
}

.line-clamp-1 {
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.line-clamp-2 {
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.line-clamp-3 {
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
