@import '/src/assets/styles/utils/variables';

.modal-dialog {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  margin: 0rem auto !important;
  padding: 4rem 0rem !important;
}

.modal-content {
  max-height: 100%;
  border-radius: $radius-card !important;
}

app-reorder-modal {
  min-height: 60vh;
  display: flex;
  flex-direction: column;
}

app-nutrition-preferences-modal, app-reward-details-modal, app-meal-name-modal, app-sm-info-modal, app-suggested-products-modal, app-reorder-modal, app-quick-add-modal, app-side-with-size-modal, app-rewards-preview-modal,  {
  display: flex;
  flex-direction: column;
}

app-rewards-preview-modal {
  overflow: hidden;
  border-radius: $radius-card;
}

// .modal-content {
//   min-height: 60vh;
// }

@media (max-width: 575.98px) {
  .modal-dialog {
    margin: 0px !important;
    padding: 0px !important;
  }

  app-nutrition-preferences-modal, app-reward-details-modal, app-meal-name-modal, app-sm-info-modal, app-suggested-products-modal, app-reorder-modal, app-quick-add-modal, app-side-with-size-modal, app-rewards-preview-modal,  {
    height: 100vh;
  }

  .modal-content {

    border-radius: 0px !important;
  }
}
