@import '/src/assets/styles/utils/variables';

$flex-display-col: 49 49 0;
$flex-display-col-center: 2 2 0;
$background-line: #464646;
$background-orange: orange;

.points-transaction-header {
  position: relative;

  &::before {
    content: '';
    background-image: url(/assets/images/patterns/Freebirds_BrandPatterns_Digital_RGB_Carbon-cleaned.svg);
    background-repeat: repeat;
    opacity: 0.075;
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
  }
}

.my-rewards-container {
  position: relative;

  &::before {
    content: '';
    background-image: url(/assets/images/patterns/Freebirds_BrandPatterns_Digital_RGB_Carbon-cleaned.svg);
    background-repeat: repeat;
    opacity: 0.075;
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
  }
}

.event-wrapper-container {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.arrow {
  width: 0;
  height: 0;
  border-top: 0.5rem solid transparent;
  border-bottom: 0.5rem solid transparent;
  border-radius: 6px;
  z-index: 10;

  &.right {
    filter: drop-shadow(2px 0px 0px #244a6e22);
    border-left: 0.5rem solid white;
  }

  &.left {
    filter: drop-shadow(-1px 0px 0px #244a6e22);
    border-right: 0.5rem solid white;
  }
}

.event {
  background: white;
  border-radius: $radius-card;
  padding: 1rem;
  width: 100%;
  margin: 1rem 0;

  &.enableAnimation:hover {
    filter: drop-shadow(0 4px 4px rgba(50, 49, 49, 0.2));
    padding: 1rem !important;
    transition: all 0.3s ease-in-out !important;
  }

  &.enableAnimation:not(:hover) {
    filter: none;
    padding: 1rem;
    transition: all 0.3s ease-in-out !important;
  }

  &:not(.hour) {
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: none !important;
    border: 1px solid $light;
  }

  &.hour {
    padding: 0.5rem;
    background: none;
    display: flex;
    justify-content: center;
    align-items: center;

    &.right {
      justify-content: flex-start;
    }

    &.left {
      justify-content: flex-end;
    }
  }

  .hour-inner-container {
    margin: 0;
  }
}

.event-container {
  display: flex;

  &.reverse {
    flex-direction: row-reverse;
  }

  .event-info-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0.1rem;
    flex: 88 88 0;

    .event-info-header {
      display: flex;
      align-items: center;
      width: 100%;

      .icon-container {
        margin-right: 0.3rem;

        nb-icon {
          color: $blue !important;
          width: 1.5rem;
          height: 1.5rem;
          margin-bottom: 0.1rem;
        }
      }

      .title-container {
        font-weight: bold;
        font-size: 15px;
      }
    }

    .event-info-body {
      padding: 0.5rem 0;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      overflow-wrap: anywhere;

      .event-info-description {
        margin-bottom: 0.5rem;
        font-size: 13px;
      }

      .expiration-container,
      .category-container {
        display: flex;
        font-size: 13px;
        justify-content: flex-start;
        align-items: center;

        p {
          font-size: 13px;
          margin: 0;
        }

        .expiration-label,
        .category-label {
          margin-right: 0.5rem;
        }

        .expiration-value,
        .category-value {
          font-weight: bold;
        }
      }
    }

    .event-info-footer {
      font-size: 15px;
      cursor: pointer;
      padding-top: 1rem;

      .footer-inner-container {
        display: flex;
        justify-content: flex-start;
        align-items: flex-end;

        nb-icon {
          color: #006cb4;
          width: 1rem;
          height: 1rem;
          margin-bottom: 0.1rem;
        }
      }

      p {
        margin: 0;
        color: #006cb4;
      }
    }
  }

  .event-divider-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.1rem;
    flex: 5 5 0;

    hr {
      height: 100%;
      width: 1px;
      background: #e9e9e9;
    }
  }

  .event-date-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 0.1rem;
    flex: 15 15 0;

    p {
      margin: 0;
      text-align: center;

      &.day {
        font-size: 32px;
        font-weight: bold;
        padding: 0.5rem 0;
      }
    }
  }
}

.main-container {
  display: flex;
  justify-content: center;
  width: 100%;
}

.items-container {
  padding: 1rem 0;
  min-width: max(20rem, 100%);
}

.row {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
}

.row > [class*='col-'] {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.col-left {
  align-items: flex-end;
  padding: 0;
  flex: $flex-display-col;
}

.col-right {
  align-items: flex-start;
  padding: 0;
  flex: $flex-display-col;
}

.col-center {
  min-height: 6rem;
  padding: 0;
  flex: $flex-display-col-center;
}

.center-inner {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;

  .line {
    min-height: 50%;
    max-height: 50%;
    height: 50%;
    background: $background-line;
    width: 0.1rem;

    &.transparent {
      background: transparent;
    }
  }
}

.period-container {
  background: $info !important;
  color: $dark !important;
  margin: 0;
  padding: 0.2rem 0.5rem;
  border-radius: $radius-card !important;

  .period-inner-container {
    width: max-content;
    padding: 0.2rem 0.5rem;
    z-index: 10;
  }
}

.center-icon-container {
  padding: 0.8rem 0;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  height: 10px;
  width: 10px;
  background: white;

  .icon {
    height: 1rem;
    width: 1rem;
    border-radius: 50%;
    background: $blue !important;
    margin: 0;
    position: absolute;
  }
}

.event-outer-container {
  margin: 0px !important;
  width: 100%;
}

.hour {
  padding: 0.5rem;
  background: none;
  display: flex;
  justify-content: center;
  align-items: center;

  &.right {
    justify-content: flex-start;
  }

  &.left {
    justify-content: flex-end;
  }

  .hour-inner-container {
    margin: 0;
  }
}

.desktop {
  display: flex !important;
}

.mobile {
  display: none !important;
}

@media (max-width: 900px) {
  .desktop {
    display: none !important;
  }

  .mobile {
    display: flex !important;
  }

  .event-outer-container {
    margin: 0.5rem 0.3rem;
  }

  .col-left {
    align-items: flex-start;

    .hour {
      font-size: 12px;
      padding: 0;
      margin-right: 0.3rem;
    }

    &.col-period {
      flex: 18 18 0;
    }

    &.col-event {
      flex: 18 18 0;
      padding: 0;
      align-items: flex-end;
    }
  }

  .col-right {
    align-items: flex-start;

    &.col-period {
      flex: 77 77 0;
    }

    &.col-event {
      flex: 77 77 0;
      padding: 0;
    }
  }

  .col-center {
    min-height: 6rem;
    justify-content: center;
    margin: 0;

    &.col-period {
      flex: 5 5 0;
      min-height: 3rem;
    }

    &.col-event {
      flex: 5 5 0;
    }
  }

  .center-inner {
    align-items: center;
  }

  .period-container {
    min-height: 20%;
    height: 20%;
    max-height: 20%;
    background: transparent;
    background: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: $radius-card !important;

    .period-inner-container {
      position: absolute;
      background: $info !important;
      color: $dark !important;
      width: max-content;
      padding: 0.2rem 0.5rem;
      z-index: 10;
      border-radius: $radius-card !important;
    }
  }

  .center-icon-container {
    align-items: center;
    justify-content: center;

    .icon {
      height: 1rem;
      width: 1rem;
      position: absolute;
    }
  }
}
