@import "src/assets/styles/utils/_variables.scss";

.bs-popover-top .popover-arrow:after {
  border-top-color: $primary !important;
}

.bs-popover-bottom .popover-arrow:after {
  border-bottom-color: $primary !important;
}

.bs-popover-start .popover-arrow:after {
  border-left-color: $primary !important;
}

.bs-popover-end .popover-arrow:after {
  border-right-color: $primary !important;
}
