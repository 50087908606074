@import '/src/assets/styles/utils/variables';

.grow {
  @for $i from 1 through length($flex-grow) {
    $g: nth(map-keys($flex-grow), $i);

    &-#{$g} {
      @extend .grow !optional;
      flex-grow: map-get($map: $flex-grow, $key: #{$g});

      @for $i from 1 through length($breakpoints) {
        $b: nth(map-keys($breakpoints), $i);
        &-#{$b} {
          @media (max-width: map-get($map: $breakpoints, $key: #{$b})) {
            flex-grow: map-get($map: $flex-grow, $key: #{$g}) !important;
          }
        }
      }
    }
  }
}

.shrink {
  @for $i from 1 through length($flex-shrink) {
    $s: nth(map-keys($flex-shrink), $i);

    &-#{$s} {
      @extend .shrink !optional;
      flex-shrink: map-get($map: $flex-shrink, $key: #{$s});
    }
  }
}

.basis {
  @for $i from 1 through length($flex-basis) {
    $b: nth(map-keys($flex-basis), $i);

    &-#{$b} {
      @extend .basis !optional;
      flex-basis: map-get($map: $flex-basis, $key: #{$b});
    }
  }
}

.gap {
  @for $i from 1 through length($flex-gap) {
    $g: nth(map-keys($flex-gap), $i);

    &-#{$g} {
      @extend .gap !optional;
      gap: map-get($map: $flex-gap, $key: #{$g});
    }
  }
}

.nav-footer-page-height {
  min-height: calc(100vh - 291px);
}

.nav-footer-page-height-fixed {
  height: calc(100vh - 115px);
}

.nav-page-height {
  min-height: calc(100vh - 80px);

  &-md {
    @extend .nav-page-height;
    min-height: calc(100vh - 80px);
  }
}

.nav-page-height-fixed {
  height: calc(100vh - 80px);
}

.page-height {
  height: 100vh;
}

// media queries

// xxl)
@media (max-width: 1399.98px) {
}

// xl)
@media (max-width: 1199.98px) {
}

// lg)
@media (max-width: 991.98px) {
}

// md)
@media (max-width: 767.98px) {
  .nav-footer-page-height {
    min-height: calc(100vh - 318px);
  }

  .nav-footer-page-height-fixed {
    height: calc(100vh - 318px);
  }

  .nav-page-height {
    min-height: calc(100vh - 64px);

    &-md {
      @extend .nav-page-height;
      min-height: auto;
    }
  }

  .nav-page-height-fixed {
    height: calc(100vh - 64px);
  }
}

// sm)
@media (max-width: 575.98px) {
}
